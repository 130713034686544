import Link from 'next/link';
import styled from 'styled-components';
import { useTheme } from 'hooks/useTheme';
import { Image } from 'components/Image';
import { useMenu } from 'hooks/useMenu';

export const LogoWrapper = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 100%;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 600px) {
    & img {
      transform: scale(0.9);
    }
  }
`;

export const Logo = () => {
  const { theme } = useTheme();
  const { setIsMenuOpen } = useMenu();

  return (
    <Link href="/" passHref>
      <LogoWrapper title="Home" onClick={() => setIsMenuOpen(false)}>
        <Image src={`/logo/${theme}.png`} width={150} height={60} layout="fixed" alt="logo" />
      </LogoWrapper>
    </Link>
  );
};
