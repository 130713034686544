import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
