module.exports = {
  COMMENT_CONTENT_TYPES: {
    ARTICLE: 'Article',
    CHALLANGE: 'Challange',
    COMIC: 'Comic',
    COMMENT: 'Comment',
    DAILY_SCREEN: 'DailyScreen',
    GAME: 'Game',
    GTICKET: 'Gticket',
    LOTTERY: 'Lottery',
    MEME: 'Meme',
    MOVIE: 'Movie',
    PODCAST: 'Podcast',
    POLL: 'Poll',
    POST: 'Post',
    TEAMPOST: 'TeamPost',
    TRADE: 'Trade',
  },
  COMMENT_CONTENT_TYPES_TABLE: {
    Article: 'articles',
    Challange: 'challanges',
    Comic: 'comics',
    Comment: 'comments',
    DailyScreen: 'daily_screens',
    Game: 'games',
    Gticket: 'gtickets',
    Lottery: 'lotteries',
    Meme: 'memes',
    Movie: 'movies',
    Podcast: 'podcasts',
    Poll: 'polls',
    Post: 'posts',
    TeamPost: 'team_posts',
    Trade: 'trades',
  },
  RATING_TYPES: {
    GAME: 'Game',
  },
  MAX_NUMBERS_OF_SLIDES: 8,
  POSTS_PER_PAGE_ON_MAIN_PAGE: 12,
  GAMES_FILTERS_KEYS: ['genre', 'payment', 'platform', 'access'],
  BLACKLISTED_USER_ERROR: 'blacklisted',
  roles: ['admin', 'viceAdmin', 'moderator', 'patron', 'user'],
  adminRoles: ['admin', 'viceAdmin', 'moderator'],
  mediaQueryMenu: '800px',
};
