import styled, { keyframes } from 'styled-components';

export const outerWrapperAnimation = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`;

export const wrapperAnimation = keyframes`
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
`;

export const OuterWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${outerWrapperAnimation} 0.3s;
`;

export const InnerWrapper = styled.div`
  overflow-y: auto;
  position: relative;
  margin: 10px;
  background-color: var(--bg-medium);
  padding: 30px;
  border-radius: 10px;
  width: 100%;
  max-width: 1000px;
  animation: ${wrapperAnimation} 0.3s;
`;

export const Exit = styled.button`
  background-color: transparent;
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  border-radius: 50%;
  font-size: 25px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  &:hover {
    background-color: var(--opacity-medium);
  }
`;
