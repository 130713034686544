import styled from 'styled-components';
import { mediaQueryMenu } from 'utils/constants';

export const Wrapper = styled.button`
  border: none;
  background: none;
  height: 20px;
  width: 24px;
  margin: 15px;
  position: relative;
  transition: opacity 0.3s;
  display: none;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.5;
  }

  @media (max-width: ${mediaQueryMenu}) {
    display: block;
  }
`;

const Element = styled.span`
  width: 24px;
  height: 2px;
  background-color: var(--font);
  border-radius: 6px;
  position: absolute;
  transition: transform 0.3s ease;
  right: 0;
  transform-origin: 100% 50%;
`;

export const Top = styled(Element)`
  top: 0;
  transform: scaleX(0.8);
  &[data-isopen='true'] {
    transform: rotate(-45deg) scaleX(1.05);
  }
`;

export const Middle = styled(Element)`
  top: 9px;
  transform: scale(1);
  &[data-isopen='true'] {
    transform: scale(0);
  }
`;

export const Bottom = styled(Element)`
  bottom: 0;
  transform: scaleX(0.4);
  &[data-isopen='true'] {
    transform: rotate(45deg) scaleX(1.05);
  }
`;
