import { Logo } from 'components/Logo';
import { LogoWrapper, OuterWrapper, Center, CenterWrapper } from './Header.styles';
import { MenuButton } from './MenuButton';

export const Header = ({ children }) => (
  <OuterWrapper>
    <CenterWrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Center>{children}</Center>
      <MenuButton />
    </CenterWrapper>
  </OuterWrapper>
);
