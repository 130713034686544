import styled from 'styled-components';
import * as PopupStyles from 'components/Popup';

export const OuterWrapper = styled(PopupStyles.OuterWrapper)`
  margin: 0 !important;
`;

export const InnerWrapper = styled(PopupStyles.InnerWrapper)`
  width: 600px;
`;

export const Title = styled.h6`
  font-size: 20px;
  font-weight: bold;
  color: var(--primary);
`;

export const Text = styled.p`
  margin: 50px 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Exit = styled.button`
  border: none;
  color: white;
  font-size: 25px;
`;
