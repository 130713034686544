import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  &[data-center='true'] {
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  &[data-margin='true'] {
    margin: 20px;
  }
`;

const animation = keyframes`
  0% {
    transform: translateY(0); 
  }
  20% {
    transform: translateY(-10px);
  }
  40% {
    transform: translateY(0);
  }
`;

export const Dot = styled.span`
  margin: 2px;
  width: 10px;
  height: 10px;
  border: 2px solid var(--font);
  background: var(--bg-medium);
  border-radius: 100%;
  animation: ${animation} 1s ${(props) => props.delay}s infinite;
`;
