import styled from 'styled-components';
import { Loading } from 'components/Loading';
import { FaTimes, FaPlus } from 'react-icons/fa';

const Wrapper = styled.button`
  border: none;
  color: black;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  padding: 13px 18px;
  border-radius: 4px;
  text-decoration: none;
  margin: 3px;

  :disabled {
    opacity: 0.6;
  }

  :not(:disabled):hover {
    opacity: 0.9;
  }

  :disabled {
    filter: grayscale(10);
    &:hover {
      cursor: not-allowed;
    }
  }

  &[data-round='true'] {
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 100%;
  }
  &[data-margin='true'] {
    margin: 5px auto;
  }
  &[data-variant='danger'] {
    background-color: var(--danger);
    color: white;
  }
  &[data-variant='facebook'] {
    background-color: #3b5998;
    color: white;
  }
  &[data-variant='ghost'] {
    background-color: transparent;
    color: var(--font);
    &:hover {
      transition: all 0.2s ease;
      background-color: var(--danger);
    }
  }
  &[data-variant='primary'] {
    color: black;
  }
`;

export const Button = ({ variant = 'primary', children, disabled, loading, ...restProps }) => (
  <Wrapper data-variant={variant} disabled={disabled || loading} {...restProps}>
    {loading ? <Loading /> : children}
  </Wrapper>
);

export const ButtonRoundDelete = (props) => (
  <Button data-round variant="danger" {...props}>
    <FaTimes size={15} />
  </Button>
);

export const ButtonRoundAdd = (props) => (
  <Button data-round {...props}>
    <FaPlus size={20} />
  </Button>
);
