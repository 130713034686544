import { FaTimes } from 'react-icons/fa';
import { Button } from 'components/Button';
import { Exit, ButtonsWrapper, InnerWrapper, OuterWrapper, Text, Title } from './DeleteModal.styles';

export const DeleteModal = ({
  onRequestClose,
  onDelete,
  title,
  loading,
  children = 'Ten proces jest nieodwracalny!',
}) => (
  <OuterWrapper>
    <InnerWrapper>
      <ButtonsWrapper>
        <Title>{title}</Title>
        <Exit onClick={onRequestClose}>
          <FaTimes />
        </Exit>
      </ButtonsWrapper>
      <Text>{children}</Text>
      <ButtonsWrapper>
        <Button variant="profile" type="button" onClick={onRequestClose} loading={loading}>
          Zamknij
        </Button>
        <Button variant="danger" type="button" onClick={onDelete} loading={loading}>
          Usuń
        </Button>
      </ButtonsWrapper>
    </InnerWrapper>
  </OuterWrapper>
);
