import styled from 'styled-components';

export const OuterWrapper = styled.header`
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: var(--bg-light);
  height: var(--header-height);
  box-shadow: var(--navbar-boxshadow);
`;

export const LogoWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
`;

export const Center = styled.div`
  flex: 1;
  height: 100%;
  margin-left: 5px;
`;

export const CenterWrapper = styled.div`
  width: var(--app-width);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
